import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Cards group information into flexible containers to let users to browse a collection of related items and actions. They show a taste of information and reveal more details upon interaction.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/card/guideline-card-1.svg",
      "alt": "anatomy card"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Card Container :`}</strong>{` This rectangular flexible container is the fundamental part of this component.`}</li>
      <li parentName="ol"><strong parentName="li">{`Card Media (Optional) :`}</strong>{` Image of context information from spesific each card item.`}</li>
      <li parentName="ol"><strong parentName="li">{`Heading (Optional) :`}</strong>{` A section for the title of each card or insert another component that will be placed in the top of the card.`}</li>
      <li parentName="ol"><strong parentName="li">{`Body :`}</strong>{` A section for the description of each card or insert another component that will be placed in the center of the card.`}</li>
      <li parentName="ol"><strong parentName="li">{`Footer (Optional) :`}</strong>{` A section for the call to action of each card or insert another component that will be placed in the bottom of the card.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Card is used to highlight content in grids. It visually shows that children elements belong together and can highlight the items on hover or selected. Don’t put too much content in a card because the cards are designed for a quick scan about a single topic with keep the cards a uniform size.`}</p>
    <p><strong parentName="p">{`Common places card appear:`}</strong>{` List of product, service, pricing, etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> You have long sections of content with similar structure. </li>
      <li> You want to organize it into related groups. </li>
      <li> You want to show all the content at once. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> You want to display only one section at a time. </li>
      <li> There is a single action applying to all the content. </li>
      <li> You have many sections with little information in each. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Provide an accessible name for the card and place cards inside of a list element whenever displaying a set of multiple cards, with a list item wrapped around each card. This list element must also have its own accessible and you should generally ensure a card does not have an excessive amount of functionality or complexity within it, as this can make it more difficult for users to operate or navigate items within the card.`}</p>
    <p>{`If used graphics and icons that do not communicate any additional information beyond what is represented in text may be considered decorative and should not provide any text alternative.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="DO" caption="Aim to have cards grouped together of similar size and content length" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/card/guideline-card-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="DON'T" caption="Incosistent card group of content and try to make a uniform grid" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/card/guideline-card-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating card :`}</p>
    <ul>
      <li parentName="ul">{`Most cards include at least a title and an action menu, in addition to an image. Buttons and a body section for metadata, or a description, are optional.`}</li>
      <li parentName="ul">{`The language needs to clearly communicate what someone will find at the card’s destination, especially in the title and button.`}</li>
      <li parentName="ul">{`Titles are short (5-7 words) and focused on the value someone will get from interacting with the card.`}</li>
      <li parentName="ul">{`Card Body is brief (about 10-15 words, but can be more depending on the complexity of the information) or complementing the title with more in-depth context.`}</li>
      <li parentName="ul">{`Keep button text at 1-3 words, using a verb phrase to state the action that someone is taking.`}</li>
      <li parentName="ul">{`Titles and action menu content are parallel in structure, straightforward, and use sentence case capitalization.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      